import React, {FC} from 'react';
import {
  ProductsPageQuery_contentfulCollectiveProductsPage_bannerProduct,
  ProductsPageQuery_contentfulCollectiveProductsPage_cardProducts,
} from '../../pages/__generated__/ProductsPageQuery';
import Img, {FluidObject} from 'gatsby-image';
import styled, {css} from 'styled-components';
import Theme from '../../theme';
import {Link} from 'gatsby';
import LinkChevron from '../../images/link-chevron.svg';
import {renderPageContent} from '../../utils/rich-text-renderer';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

interface ProductListCardProps {
  product: ProductsPageQuery_contentfulCollectiveProductsPage_cardProducts;
  fullWidth?: boolean;
  isNew?: boolean;
}

const {space, breakpoints, colours} = Theme;

interface ContainerProps {
  fullWidth?: boolean;
  isNew?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({fullWidth}) => (fullWidth ? 'row' : 'column')};
  width: ${({fullWidth}) => (fullWidth ? '95%' : '45%')};
  margin-bottom: ${space[4]}px;
  justify-content: space-between;
  align-items: center;

  ${({fullWidth}) =>
    fullWidth &&
    css`
      margin: 30px auto;
      min-height: 300px;
    `}

  position: relative;
  background: ${colours.lightestGrey};

  @media screen and (max-width: ${breakpoints[0]}px) {
    width: 90%;
    flex-direction: column;
  }
  ${({isNew}) =>
    isNew &&
    css`
      :before {
        z-index: 5;
        content: 'NEW';
        position: absolute;
        height: 37px;
        width: 85px;
        margin: auto;
        top: ${space[4]}px;
        left: -${space[2]}px;
        color: ${colours.white};
        background: ${colours.blue};
        font-weight: bold;
        padding-top: 5px;
        padding-left: ${space[4]}px;
      }
    `}
`;

const TextWrapper = styled.div<ContainerProps>`
  ${({fullWidth}) =>
    !fullWidth &&
    css`
      height: 100%;
    `}
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h4,
  div,
  p {
    margin: ${space[3]}px ${space[4]}px;
  }

  h4 {
    padding-top: ${space[3]}px;
  }

  a {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
  }
  img {
    padding-left: ${space[5]}px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: grid;
  align-items: stretch;
`;

export const ProductListCard: FC<ProductListCardProps> = ({
  product,
  fullWidth,
  isNew = false,
}) => {
  const href = `/products/${product.productSlug}`;
  return (
    <Container fullWidth={fullWidth} isNew={isNew}>
      <ImageWrapper>
        <Link to={href}>
          <Img fluid={product.productImage?.fluid as FluidObject} />
        </Link>
      </ImageWrapper>
      <TextWrapper fullWidth={fullWidth}>
        <Link to={href}>
          <h4>{product.productName}</h4>
        </Link>
        {renderPageContent(
          product.description as RenderRichTextData<ContentfulRichTextGatsbyReference>,
          {isBlogPost: false}
        )}
        <div className="link-wrapper">
          <Link to={href}>
            Learn more
            <img src={LinkChevron} alt="" />
          </Link>
        </div>
      </TextWrapper>
    </Container>
  );
};
